<template>
  <footer class="footer d-flex flex-column flex-md-row align-items-center justify-content-between">
    <p class="text-muted text-center text-md-left">
      Copyright © 2021 wappin. All rights reserved
    </p>
    <p class="text-muted text-md-right">
      Version: {{ $store.state.ui.packageVersion }}
    </p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
